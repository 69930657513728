import Modal from "@/components/Modal";
import Button from "@/components/Common/Buttons/Button";
import TickIcon from "@/components/Common/Icons/TickIcon.vue";
import CrossIcon from "@/components/Common/Icons/CrossIcon.vue";

export default {
    name: 'AdminVendorListItem',
    components: {CrossIcon, TickIcon, Button, Modal},
    props: {
        id: {
            type: String,
            default: ''
        },
        sku: {
            type: String,
            default: ''
        },
        visible: {
            type: Boolean,
            default: false
        },
        price: {
            type: String,
            default: ''
        },
        category: {
            type: String,
            default: ''
        },
        phone: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        quantity: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: ''
        },
        image: {
            type: String,
            default: ''
        },
        isVip: {
            type: Boolean,
            default: false
        },

    },
    mounted() {
    },
    data() {
        return {
            translation: {
                visible: 'Видим',
                quantity: 'Количество'
            }

        }
    },
    methods: {
        deleteProduct(id) {
            this.$emit('itemDeleted', id)
        }
    }
}